import { getUtmParamsFromSession } from './helpers.js';
import { getFormFields } from './get-constants.js';

export function checkFields({
  container,
  inputSelectType = 'name',
  customFormFields = {},
}) {
  const { FORM_FIELD_IDS_LIST, FORM_FIELD_IDS_TO_UTM_MAP } = getFormFields({
    customFormFields,
  });
  const utmParams = getUtmParamsFromSession();

  FORM_FIELD_IDS_LIST.forEach(id => {
    let field;
    let selector = container || '';

    if (inputSelectType === 'className') {
      selector += ` input.${id}`;
    } else if (inputSelectType === 'name') {
      selector += ` input[name='${id}']`;
    } else if (inputSelectType === 'id') {
      selector += ` input#${id}`;
    }

    field = document.querySelector(selector.trim());

    if (field) {
      field.value = utmParams[FORM_FIELD_IDS_TO_UTM_MAP[id]] || '';
    }
  });
}
